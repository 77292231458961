@import url('https://fonts.googleapis.com/css2?family=Oswald&family=Poppins:wght@700&display=swap');

h1{
  font-family: 'Oswald', sans-serif;
}

p{
  font-family: 'Oswald', sans-serif;
}

h2{
  font-family: 'Oswald', sans-serif;
}

h3{
  font-family: 'Oswald', sans-serif;
}

button{
  background: linear-gradient(to right, #bb1d0c 50%, #E6240F 50%);
  background-size: 200% 100%;
  background-position:right bottom;
}

button:hover{
  background-position:left bottom;
  transition:all 0.4s ease;
}

.links button{
  background: #001a2b;
}

.links button:hover{
  filter: brightness(50%);
  transition:all 0.4s ease;
}

input{
  cursor: text !important;
}

input::selection{
  background-color: #001a2b;
}

.chatBar img:hover{
  filter: brightness(50%);
}

.menuItems{
  background-color: white;
}

.menuItems h1:hover{
  filter: brightness(50%);
  cursor: pointer;
}

.bar{
  border-top: 3px solid #bbb;
  width: 15vw;
  margin-left: 10%;
}

.wait{
  display:flex;
  align-items: center;
}

.d {
  float: left;
  margin: 0 4px;
  background: #001a2b;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  animation: typing 2s infinite;
  animation-timing-function: linear;
}

.d:nth-child(1) {
  animation-delay: 0s;
}

.d:nth-child(2) {
  animation-delay: 0.25s;
}

.d:nth-child(3) {
  animation-delay: 0.5s;
}

@keyframes typing {
  0% {
    width: 8px;
    height: 8px;
  }
  25% {
    width: 12px;
    height: 12px;
    margin: 0 4px;
  }
  50% {
    width: 8px;
    height: 8px;
  }
  100% {
    width: 8px;
    height: 8px;
  }
}

::-moz-selection {
  background: #8EE9F6 !important;
  color:white !important;
}
::selection {
  background: #8EE9F6 !important;
  color:white !important;
}